import { useState } from 'react';
import i18n, { configure } from 'i18n-smart';

import logo from './assets/i/logo.png';
import './App.css';
import { locales, values, contacts } from './constants';

const initialLocale = locales.ru;
const initialValues = values[initialLocale];
configure({ locale: initialLocale, defaultValues: initialValues });

function App() {
  const [locale, setLocale] = useState(initialLocale);

  function setLanguage() {
    let nextLocale = locales.ru;
    if (locale === locales.ru) nextLocale = locales.en;

    i18n.setValues(values[nextLocale], nextLocale);
    setLocale(nextLocale);
  }

  return (
    <div className="app" key={locale}>
      <header className="header">
        <img src={logo} className="app-logo" alt="logo" />
        <span onClick={() => setLanguage(locale)} className="lang-btn">
          {locale === locales.ru ? locales.en : locales.ru}
        </span>
      </header>
      <main className="main">
        <h1 className="h1">{i18n.value('company.name')}</h1>
        <section className="section">
          <div className="row pb-3">
            <h5 className="h5">
              {i18n.value('company.reg.number')} <span className="info">193157021</span>
            </h5>
          </div>
          <div className="row pb-3">
            <h5 className="h5">
              {i18n.value('company.country.inc')}
              <span className="info"> {i18n.value('company.country.inc.value')}</span>
            </h5>
          </div>{' '}
          <div className="row pb-3">
            <h5 className="h5">
              {i18n.value('company.address.business')}
              <span className="info">{i18n.value('company.address.value')}</span>
            </h5>
          </div>
          <div className="row pb-3">
            <h5 className="h5">
              {i18n.value('company.address.physical')}
              <span className="info">{i18n.value('company.address.value')}</span>
            </h5>
          </div>
          <div className="row pb-3">
            <h5 className="h5">
              {i18n.value('date.reg')}
              <span className="info">24.10.2018</span>
            </h5>
          </div>
          {/*<div className="row pb-3">*/}
          {/*  <h5 className="h5">*/}
          {/*    {i18n.value('contact.number')}*/}
          {/*    <a href={contacts.phone.link} className="link">*/}
          {/*      <span className="info">{contacts.phone.text}</span>*/}
          {/*    </a>*/}
          {/*  </h5>*/}
          {/*</div>*/}
        </section>
      </main>
    </div>
  );
}

export default App;
