export const locales = {
  ru: 'RU',
  en: 'EN',
}

export const contacts = {
  phone: {
    link: 'tel:+375293305896',
    text: '+375 (29) 330-58-96'
  }
}

export const values = {
  [locales.en]: {
    'company.name': 'LLC “BC Leon”',
    'company.reg.number': 'Company Registration Number: ',
    'company.country.inc': 'Country of Incorporation: ',
    'company.country.inc.value': 'Republic of Belarus Registered',
    'company.address.business': 'Registered Business Address: ',
    'company.address.physical': 'Physical presence address: ',
    'company.address.value': 'Republic of Belarus, Minsk, Petra Mstislavca street, 13, 269 room',
    'date.reg': 'Date of Registration: ',
    'contact.number': 'Сontact number: ',
  },
  [locales.ru]: {
    'company.name': 'ООО «БК Леон»',
    'company.reg.number': 'Регистрационный номер компании: ',
    'company.country.inc': 'Страна регистрации: ',
    'company.country.inc.value': 'Республика Беларусь',
    'company.address.business': 'Адрес регистрации: ',
    'company.address.physical': 'Адрес компании: ',
    'company.address.value': 'Республика Беларусь, г. Минск, ул. Петра Мстиславца, д.13, пом. 269',
    'date.reg': 'Дата регистрации: ',
    'contact.number': 'Телефон: ',
  },
}
